/**
 * @file
 * Behaviors for the Podcast.
 */

!((Drupal, $) => {
  'use strict';

  Drupal.behaviors.podcast = {
    attach: function(context) {
      const player = new Plyr('#podcast-player');
      let active = null;
      // set click event on play icon
      $('.play-button', context).on('click', function() {
        const podcastUrl = $(this)
          .parents('.episodes__play')
          .data('podcast');
        const id = $(this)
          .parents('.episodes__play')
          .data('id');
        setSource('play', podcastUrl, id);
        // display player
        $('.player__section').addClass('active');
      });

      $('.pause-button').on('click', function() {
        const id = $(this)
          .parents('.episodes__play')
          .data('id');
        setSource('pause', {}, id);
      });

      const setSource = (status, url, id) => {
        if (active !== id) {
          active = id;
          player.source = {
            type: 'audio',
            title: 'AHO Podcasts',
            sources: [
              {
                src: url,
                type: 'audio/mp3'
              }
            ]
          };
          player.id = id;
        }
        if (status === 'play') {
          player.play();
        }
        else {
          player.pause();
        }
      };

      $('.player__section .aho-navigation__button-active').on(
        'click',
        function() {
          $('.player__section').removeClass('active');
          player.stop();
          $('.pause-button').addClass('hidden');
          $('.play-button').removeClass('hidden');
        }
      );

      // set event on play button on player

      player.on('play', data => showPause(data));
      player.on('pause', data => showPlay(data));

      const showPause = data => {
        const { id } = data.detail.plyr;
        const el = findElementBasedOnId(id);

        var playButton = el.querySelector('.play-button');
        var pauseButton = el.querySelector('.pause-button');

        if (playButton) {
          playButton.classList.add('hidden');
        }
        if (pauseButton) {
          pauseButton.classList.remove('hidden');
        }
      };

      const showPlay = data => {
        const { id } = data.detail.plyr;
        const el = findElementBasedOnId(id);

        var playButton = el.querySelector('.play-button');
        var pauseButton = el.querySelector('.pause-button');

        if (playButton) {
          playButton.classList.remove('hidden');
        }
        if (pauseButton) {
          pauseButton.classList.add('hidden');
        }
      };

      function findElementBasedOnId(id) {
        let el;
        $(
          '.view-podcast-archive.view-display-id-podcast_episode .views-row'
        ).each(function(index, item) {
          if (
            $(item)
              .find('.episodes__play')
              .data('id') === id
          ) {
            el = item;
          }
        });
        return el;
      }
    }
  };
})(Drupal, jQuery);
